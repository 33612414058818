<template>
    <div>
        <v-toolbar
                dense
                color="primary darken-2"
                dark
                extended
                extension-height="210"
                flat
        >
            <v-toolbar-title class="mx-auto" slot="extension">
                <span v-if="$store.state.isAuthenticated">Welcome <b>{{$store.state.user_info["user"]["username"]}} 😃</b>, to PettyCash: MarketPlace</span>
                <span v-else >Welcome 😃 to <b>PettyCash: MarketPlace</b>,</span>
                <h2 class="saving_heading">Shop at cheap prices/credit at our market place.</h2>
                <v-layout row >
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-coins fa-3x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-plus fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                       <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-shopping-cart fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs1 sm1 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-arrow-right-long fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs2 sm3 md3 lg3 >
                        <v-img
                            :src="require('@/assets/market_place_items.png')"
                            aspect-ratio="2"  
                            contain
                            height="90"
                            width="120"
                        ></v-img>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container class="mt-5">
            <v-layout row>
                <v-flex xs12 md10 offset-md1 lg10 offset-lg1>
                    <v-card flat class="card--flex-toolbar" color="transparent">
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12 class="mt-0 pt-0 mb-2">
                                    <h3 class="white--text">Explore Petty Ca$h Market Place</h3>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 lg4 v-for="item in links" :key="item.id">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small" :to="item.link">
                                            <v-layout row >
                                                <v-flex xs8>
                                                    <v-btn fab :color="item.color" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i :class="item.icon"></i>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex xs4 v-if="!(item.title == 'Legal Bindings')" class="text-xs-right">
                                                    <v-menu bottom right >
                                                        <v-btn slot="activator" icon flat fab color="mt-2 ml-3 mb-0" v-on:click.prevent="activateMenu(item.title)">
                                                            <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                                                        </v-btn>
                                                        <v-list class="grey lighten-4">
                                                        <v-list-tile v-for="menuLink in menuItems" :key="menuLink.title" :to="menuLink.link">
                                                            <v-list-tile-title>{{ menuLink.title }}</v-list-tile-title>
                                                        </v-list-tile>
                                                        </v-list>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <h5 class="subheading pl-0 pb-0 mb-0"><b>{{item.title}}</b></h5>
                                                    <div class="caption mt-0 mb-0 pb-2">{{item.description}}</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-container>
                            <h2 class="ma-1 underline">Most Bought Items</h2>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 v-for="item in mostBoughtItems" :key="item.id">
                                    <v-hover>
                                        <v-card color="white" slot-scope="{ hover }" :class=" `ma-1 elevation-${hover ? 12 : 2}`"  :to="'/market_place/items/'+item.id" style="border-radius: 5px">
                                            <v-container fluid grid-list-lg>
                                            <v-layout row wrap>
                                                <v-flex xs12 sm5 md4 class="pa-0 ma-0">
                                                    <v-img
                                                        :src="item.get_image"
                                                        height="150px" 
                                                        contain 
                                                    ></v-img>
                                                </v-flex>
                                                <v-flex xs12 sm7 md8 class=" ma-0 pa-0">
                                                    <div class="ml-1">
                                                        <div class="title ma-0 pa-0 text-xs-left"><u>{{item.name| truncate(23, '...') }}</u></div>
                                                        <div>
                                                            <v-layout row wrap class=" ma-0 pa-0">
                                                                <v-flex xs12 text-xs-left class=" ma-0 pt-2 pb-0 px-0">
                                                                    <span class="dark--text font-weight-bold">Description:</span> {{ item.description| truncate(55, '...')  }}
                                                                </v-flex>
                                                                <v-flex xs8 text-xs-left class=" ma-0 pa-0">
                                                                    <span class="dark--text font-weight-bold">Cost:</span><span> {{item.price}} FCFA</span>
                                                                </v-flex>
                                                                <v-flex xs4 text-xs-right class=" ma-0 pa-0">
                                                                    <span class="dark--text font-weight-bold">Qty:</span><span> {{item.quantity}}</span>
                                                                </v-flex>
                                                                <v-flex xs6 text-xs-left class=" ma-0 pa-0">
                                                                    <span class="dark--text font-weight-bold">In-Stock:</span><span> {{item.quantity}}</span>
                                                                </v-flex>
                                                                <v-flex xs6 text-xs-right class=" ma-0 pa-0">
                                                                    <span class="dark--text font-weight-bold">Type:</span><span> {{item.category}}</span>
                                                                </v-flex>
                                                            </v-layout>
                                                        </div>
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                            </v-container>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 class="text-xs-right">
                                    <v-btn class="blue elevation-6" round color="primary" to="/market_place/items/types/all" >
                                        <span> View All Items </span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                links: [
                    {
                        id: '1',
                        title: 'Market Items',
                        description: 'View all the items available in our market place and purchase any item of interest',
                        color: 'teal',
                        icon: 'fa-solid fa-store-alt fa-2x',
                        link: '/market_place/items/types/all',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'Shopping Bag',
                        description: 'View the items you have selected and want to purchase.',
                        color: 'orange',
                        icon: 'fa-solid fa-shopping-basket fa-2x',
                        link: '/market_place/shopping_bag/items/my_items',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Shopping History',
                        description: 'View all your previous transactions made, items bought and favorite items.',
                        color: 'blue',
                        icon: 'fa-solid fa-history fa-2x',
                        link: '/market_place/history/items/types/favorite',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Contact Us',
                        description: 'Get in contact with us if you have any worries/suggestions concerning our services',
                        color: 'green',
                        icon: 'fa-solid fa-address-card fa-2x',
                        link: '/market_place/contact_us/all',
                        add_link: false
                        },
                    {
                        id: '5',
                        title: 'Legal Bindings',
                        description: 'Learn of the rules, legal and social rights that protect you as you shop with Petty Cash',
                        color: 'purple',
                        icon: 'fa-solid fa-file-contract fa-2x',
                        link: '/market_place/legal_binding/all',
                        add_link: false
                    }
                ],
                mostBoughtItems: [],
                menuItems: [
                ],

            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Market Place"
            this.getMostBoughtItems()
        },

        methods:{
            activateMenu(title){
                if(title == "Market Items"){
                    this.menuItems = [
                        {
                            "title": "All",
                            "link": "/market_place/items/types/all",
                        },
                        {
                            "title": "Cheapest",
                            "link": "/market_place/items/types/cheapest",
                        },
                        {
                            "title": "Most Bought",
                            "link": "/market_place/items/types/best",
                        },
                    ]
                }
                else if(title == "Shopping Bag"){
                    this.menuItems = [
                        {
                            "title": "My Items",
                            "link": "/market_place/shopping_bag/items/my_items",
                        },
                        {
                            "title": "Check Out",
                            "link": "/market_place/shopping_bag/items/billing_receipt",
                        },
                    ]
                }
                else if(title == "Shopping History"){
                    this.menuItems = [
                        {
                            "title": "Favorite",
                            "link": "/market_place/history/items/types/favorite",
                        },
                        {
                            "title": "Recent",
                            "link": "/market_place/history/items/types/recent",
                        },
                        {
                            "title": "All",
                            "link": "/market_place/history/items/types/all",
                        },
                    ]
                }
                else if(title == "Contact Us"){
                    this.menuItems = [
                        {
                            "title": "All Chats",
                            "link": "/market_place/contact_us/all",
                        },
                        {
                            "title": "Create New Chat",
                            "link": "/market_place/contact_us/new_ticket",
                        },
                    ]
                }
            },
            
            async getMostBoughtItems(){
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/market_place/get/latest_products/best/info/')  // best items
                    .then(response => {
                        this.mostBoughtItems = response.data  // get the data and fill into most Bought Items
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
        },
    }
</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }
    @media (max-width: 499px) {
        .saving_heading{
            font-size: 12px
        }
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>
